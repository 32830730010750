<script context="module" lang="ts">
	import Image from '$lib/components/Image.svelte';
	import icoEdit from 'iconoir/icons/edit-pencil.svg';
	import icoArrowRight from 'iconoir/icons/nav-arrow-right.svg'

	import getPermissions from '../auth/getPermissions';

	import * as timeago from 'timeago.js';

	const defaultImage = import.meta.env.VITE_DEFAULTIMAGE;

	async function canEdit() {
		return await getPermissions('edit-news');
	}
</script>

<script lang="ts">
	export let id = null;
	export let title = 'Undefined';
	export let titleLength = 30;
	export let body = 'Undefined';
	export let bodyLength = 120;
	export let image = defaultImage;
	export let imageAlt = '';
	export let imageFocal = 'center center';
	export let dateMsg = '';

	if (image == '') {
		image = defaultImage;
	}

	let truncPostTitle = '';
	if (title.length > titleLength) {
		truncPostTitle = title.substring(0, titleLength) + '...';
	} else {
		truncPostTitle = title;
	}

	let truncSummary = '';
	if (body.length > bodyLength) {
		truncSummary = body.substring(0, bodyLength) + '...';
	} else {
		truncSummary = body;
	}
</script>

<div
	class="flex flex-row items-stretch gap-x-4 rounded-md bg-white p-2 drop-shadow-sm md:p-0 md:pb-2 md:pr-4"
>
	<div class="basis-1/3 self-stretch md:basis-1/4">
		<img
			src={image}
			alt={imageAlt}
			class="h-full max-h-32 w-full rounded-md object-cover"
			style="object-position: {imageFocal}"
		/>
	</div>
	<div class="md:basis3/4 relative basis-2/3">
		<a href="/news/{id}">
			<h3 class="text-xl">{truncPostTitle}</h3>
			<p class="mb-6 leading-tight">{truncSummary}</p>

			<div class="absolute bottom-0">
				<!--                <p class="text-zinc-700" id={dateMsg}>{timeago.format(dateMsg, 'en_US')}</p>-->
				<p class="text-gray-700">Read More <img src={icoArrowRight} alt="Read More Icon" class="inline -mt-0.5 w-5 h-5" style="filter: invert(21%) sepia(41%) saturate(312%) hue-rotate(178deg) brightness(97%) contrast(91%);"></p>
			</div>
		</a>
		{#await canEdit()}
			<div />
		{:then result}
			{#if result == true}
				<div class="absolute bottom-0 right-0">
					<a href="/news/{id}/edit" class="flex items-center">
						<img
							src={icoEdit}
							alt="Edit Icon"
							class="inline-block h-4"
							style="filter: invert(21%) sepia(19%) saturate(259%) hue-rotate(202deg) brightness(95%) contrast(86%);"
						/>
						<p class="ml-2 text-zinc-700">Edit</p>
					</a>
				</div>
			{/if}
		{/await}
	</div>
</div>
