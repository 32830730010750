<script lang="ts" context="module">
	import Post from '$lib/components/news/Post.svelte';
	import Spinner from '$lib/components/Spinner.svelte';
	import icoAdd from 'iconoir/icons/plus-circle.svg';

	import { apiUrl } from '$lib/settings/api';

	export async function loadPosts() {
		const url =
			apiUrl +
			'news/news/index?limit=5&filter=[{"field":"postStatus","operation":"eq","value":"published"}]';
		const res = await fetch(url);
		let apiResponse = await res.json();
		let posts = apiResponse['data']['documents'];
		return posts;
	}

	import checkPerm from '../auth/getPermissions.js';
	async function canCreate() {
		return await checkPerm('create-news');
	}
</script>

<script lang="ts">
	export let titleLength = 20;
	export let bodyLength = 80;
</script>

{#await canCreate()}
	<div />
{:then result}
	{#if result == true}
		<div class="">
			<a href="/news/create/"><img class="float-right w-6" src={icoAdd} alt="Add News" /></a>
		</div>
		<div class="clear-right" />
	{/if}
{/await}

<div class="posts mt-4">
	{#await loadPosts()}
		<Spinner />
	{:then posts}
		{#if posts.length == 0}
			<div class="rounded-md bg-white p-2 pb-4 drop-shadow-sm md:p-0">
				<p class="text-center text-lg font-medium">No News Currently!</p>
				<p class="pt-4 text-center">Check back later to see our latest news, info and more :)</p>
			</div>
		{/if}
		{#each posts as post}
			<Post
				id={post._id}
				title={post.postTitle}
				{titleLength}
				image={post.postImgLink}
				imageAlt={post.postImgAlt}
				imageFocal={post.postImgFocal}
				dateMsg={post.postPublishedDate}
				body={post.postSummary}
				{bodyLength}
			/>
			<br />
		{/each}
	{/await}
</div>
